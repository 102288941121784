import { requestErrorToastManager } from "../Utils/Toasts";
import DataTable from "./DataTable";
import { useInvestmentInfiniteQuery } from "./Queries";

export default function DataTableContainer({
	city,
	promotion,
	begin,
	end,
	alert,
	stats,
}) {
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		error,
	} = useInvestmentInfiniteQuery(city?.value, promotion, begin, end, alert);

	if (!isFetchingNextPage && hasNextPage) fetchNextPage();
	const students = data?.pages.flatMap((page) => page) ?? [];

	requestErrorToastManager("Investement", error);

	return <DataTable isFetching={isFetching} data={students} stats={stats} />;
}
