import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardDeck, Container, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { SauronRoutes } from "../../Config/Routes";
import { t } from "../../Config/i18n";
import EpitechAvatar from "../Utils/EpitechAvatar";
import { Box } from "./Box";

import "./Modal.scss";

export default function StudentSearchModal({ opened, students, onClose }) {
	const displayStudents = (stds) =>
		stds.map((student) => (
			<Link
				key={student.login}
				to={SauronRoutes.student.path.replace(":login", student.login)}
				onClick={onClose}
				className="mt-3"
			>
				<Box style={{ width: "15rem" }}>
					<Card.Header className="text-center">
						<EpitechAvatar
							login={student.login}
							className="img elevation-4"
							style={{
								borderRadius: "15px",
								maxWidth: "100%",
							}}
							onErrorRender={
								<FontAwesomeIcon
									icon={faUserTie}
									data-place="bottom"
									data-tooltip-content={t(
										"no profile picture found"
									).capfirst()}
									data-tooltip-id="student-search-modal-tooltip"
									size="10x"
									color="black"
								/>
							}
						/>
					</Card.Header>
					<Card.Body>
						<Card.Title>
							{student.firstname}
							<br />
							{student.lastname}
						</Card.Title>
						<Card.Text>
							{`${student.promo.cursus.code.capfirst()} ${student.promo.city.name.capfirst()} ${
								student.promo.promotion_year
							}`}
							<br />
							{`${student.credit_acquired} ${t("credits")}`}
						</Card.Text>
					</Card.Body>
				</Box>
			</Link>
		));

	if (students === null || students.length === 0) {
		return (
			<Modal show={opened} backdrop={false} onHide={onClose}>
				<Modal.Header closeButton>
					<Modal.Title>
						{t("no students found").capfirst()}
					</Modal.Title>
				</Modal.Header>
			</Modal>
		);
	}
	return (
		<Modal
			show={opened}
			onHide={onClose}
			dialogClassName="modal-90w"
			animation={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{t("multiple students found").capfirst()}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Container fluid>
					<CardDeck className="justify-content-center">
						{displayStudents(students)}
					</CardDeck>
				</Container>
			</Modal.Body>
			<Tooltip id="student-search-modal-tooltip" />
		</Modal>
	);
}
