import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useCallback, useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useProjectsAvailableYearQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["projects", "filters_options", "years"],
		queryFn: () => scraper.projects.getAvailableYears(),
		select: useCallback((data) => data.available_years, []),
	});
};

export const useAvailableProjectsQuery = (year, module_code = null) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"projects",
			"filters_options",
			"projects",
			year,
			module_code,
		],
		queryFn: ({ pageParam }) =>
			scraper.projects.getAvailableProjects(pageParam, year, module_code),
		select: useCallback(
			(data) => data.pages.flatMap((page) => page.results),
			[]
		),
		initialPageParam: 0,
		getNextPageParam,
	});
};

export const useAvailableProjectInstancesQuery = (year, module_code, slug) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"projects",
			"filters_options",
			"project_instances",
			year,
			module_code,
			slug,
		],
		queryFn: () =>
			scraper.projects.getAvailableProjectInstances(
				year,
				module_code,
				slug
			),
		enabled: !!year && !!module_code && !!slug,
	});
};
