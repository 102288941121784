import {
	faClipboard,
	faMinusCircle,
	faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import { useContext } from "react";
import { Button } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import { compare } from "../Utils/Utils";
import {
	useRevokeAccessTokenMutation,
	useRevokeApiTokenMutation,
	useRevokeRefreshTokenMutation,
} from "./Queries";

function unixTsToReadable(unix_ts) {
	if (!unix_ts) {
		return t("never").capfirst();
	}
	return DateTime.fromSeconds(unix_ts).toLocaleString(DateTime.DATETIME_FULL);
}

function ClipboardToken({ token }) {
	return (
		<CopyToClipboard
			className="mr-3 btn-sm"
			text={token}
			onCopy={() =>
				toast.success(
					`Sauron: ${t("token copied successfully").capfirst()}`
				)
			}
		>
			<Button
				variant="secondary"
				className="btn-sm"
				data-tooltip-id="jwt-manager-tooltip"
				data-tooltip-content={t("copy").capfirst()}
			>
				<FontAwesomeIcon icon={faClipboard} size="lg" />
			</Button>
		</CopyToClipboard>
	);
}

export default function TokenDataTable({ data, type, isLoading, isFetching }) {
	const scraper = useContext(ScraperApiHelperContext);
	const revoke_mutations = {
		access: useRevokeAccessTokenMutation(),
		refresh: useRevokeRefreshTokenMutation(),
		api: useRevokeApiTokenMutation(),
	};

	const originalColumns = [
		{
			id: "email",
			accessorFn: (row) => row.email,
			header: t("email").capfirst(),
			Cell: ({ row }) => row.original.email || "-",
			enableSorting: true,
			sortingFn: (a, b) => compare(a.original.email, b.original.email),
		},
		{
			id: "groups",
			accessorFn: (row) => row.groups,
			header: t("groups").capfirst(),
			Cell: ({ row }) => row.original.groups.sort().join(", ") || "-",
			enableSorting: true,
			sortingFn: (a, b) => compare(a.original.groups, b.original.groups),
		},
		{
			id: "issued_date",
			accessorFn: (row) => row.iat,
			header: t("issued date").capfirst(),
			enableSorting: true,
			sortingFn: (a, b) => compare(a.original.iat, b.original.iat),
			Cell: ({ row }) => unixTsToReadable(row.original.iat),
		},
		{
			id: "expire_date",
			accessorFn: (row) => row.exp,
			header: t("expire date").capfirst(),
			enableSorting: true,
			sortingFn: (a, b) => compare(a.original.exp, b.original.exp),
			Cell: ({ row }) => unixTsToReadable(row.original.exp),
		},
		{
			id: "jwt",
			accessorFn: (row) => row.jwt,
			header: "JWT",
			Cell: ({ row }) => (
				<>
					<ClipboardToken token={row.original.token} />
					<Button
						variant="danger"
						className="btn-sm"
						data-tooltip-id="jwt-manager-tooltip"
						data-tooltip-html={`${t("revoke").capfirst()}${
							row.original.token ===
								scraper.api.getSauronAccountInfo().token ||
							row.original.token ===
								scraper.api.getSauronAccountInfo().refresh_token
								? `<br/>(${t(
										"this is your current session token"
									).capfirst()})`
								: ""
						}`}
						onClick={() =>
							revoke_mutations[type].mutate(row.original.token)
						}
					>
						<FontAwesomeIcon
							icon={
								row.original.token ===
									scraper.api.getSauronAccountInfo().token ||
								row.original.token ===
									scraper.api.getSauronAccountInfo()
										.refresh_token
									? faMinusCircle
									: faTrashAlt
							}
							size="lg"
						/>
					</Button>
				</>
			),
		},
	];
	const finalColumns =
		type !== "api"
			? originalColumns
			: originalColumns
					.map((a) => ({ ...a }))
					.filter(({ id }) => id != "expire_date");
	return (
		<SauronMantineDataTable
			columns={finalColumns}
			data={data}
			isLoading={isLoading}
			isFetching={isFetching}
		/>
	);
}
