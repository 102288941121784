import { EventType } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import Router from "../Router/Router";
import AzureLoadingPage from "./LoadingPage";
import { loginRequest } from "./MicrosoftOAuthProvider";

function OauthHandler() {
	const { instance } = useMsal();
	const [connectState, setConnectState] = useState({
		state: "loading",
		message: "we are connecting you to Sauron",
	});
	const scraper = useContext(ScraperApiHelperContext);

	const verifySauronToken = async () => {
		const verifyResponse = await scraper.api.verify();
		scraper.api.setSauronUserInfos(verifyResponse.data);
	};

	const verifyOrRefreshSauronToken = async () => {
		try {
			const verifyResponse = await verifySauronToken();
			return verifyResponse;
		} catch (_) {
			const refreshResponse = await scraper.api.refresh();
			scraper.api.setSauronToken(refreshResponse.data);
			return verifySauronToken();
		}
	};

	const loginToSauron = async (token) => {
		const loginResponse = await scraper.api.login(token);
		scraper.api.setSauronAccountInfo(loginResponse.data);
		return verifyOrRefreshSauronToken();
	};

	const loginWithAzure = () => {
		setConnectState({
			state: "loading",
		});
		instance.loginRedirect(loginRequest);
	};

	useEffect(() => {
		let loginAlreadyInProgress = false;

		const callbackId = instance.addEventCallback((event) => {
			if (
				(event.eventType === EventType.LOGIN_SUCCESS ||
					event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
				event.payload.account
			) {
				const MsalAccount = event.payload.account;
				instance.setActiveAccount(MsalAccount);
				instance.setActiveAccount(event.payload.account);
				scraper.api.setMicrosoftAccountInfo(event.payload.account);
				if (loginAlreadyInProgress === false) {
					setConnectState({
						state: "loading",
						message:
							"Microsoft login successful, we are verifying your rights to access Sauron",
					});
					loginAlreadyInProgress = true;
					loginToSauron(event.payload.idToken)
						.then(() => {
							setConnectState({
								state: "connected",
							});
						})
						.catch(() => {
							setConnectState({
								state: "error",
							});
						});
				}
			} else if (
				event.eventType === EventType.HANDLE_REDIRECT_END &&
				!loginAlreadyInProgress
			) {
				loginAlreadyInProgress = true;
				verifyOrRefreshSauronToken()
					.then(() => {
						setConnectState({
							state: "connected",
						});
					})
					.catch(() => {
						setConnectState({
							state: "disconnected",
						});
					});
			}
		});
		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
			loginAlreadyInProgress = false;
		};
	}, []);

	switch (connectState.state) {
		case "connected":
			return <Router />;
		case "error":
			return (
				<AzureLoadingPage
					message="could not obtain an access token from the server, verify that you have access to this website"
					isDisconnected
					callback={() => loginWithAzure()}
				/>
			);
		case "disconnected":
			return (
				<AzureLoadingPage
					isDisconnected
					callback={() => loginWithAzure()}
				/>
			);
		default:
			return <AzureLoadingPage message={connectState.message} />;
	}
}

export default OauthHandler;
