import { Tooltip } from "react-tooltip";
import { t } from "../../Config/i18n";
import EpitechAvatar from "./EpitechAvatar";

export default function StudentTooltip({ tooltipId }) {
	return (
		<Tooltip
			id={tooltipId}
			place="right"
			className="rounded"
			render={({ content }) => (
				<div className="text-center">
					{!content ? (
						t("problem loading login").capfirst()
					) : (
						<div className="d-flex flex-column">
							<h5>
								{!content.split(";")[0]
									? t("problem loading login").capfirst()
									: content
											.split(";")[0]
											.replace("@epitech.eu", "")}
							</h5>
							<EpitechAvatar
								login={content.split(";")[0]}
								style={{ scale: 0.5 }}
							/>
						</div>
					)}
				</div>
			)}
		/>
	);
}
