import { ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { DatesProvider } from "@mantine/dates";
import "dayjs/locale/fr";
import QS from "qs";
import { Suspense, useContext, useEffect, useState } from "react";
import {
	Route,
	BrowserRouter as Router,
	Routes,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { SauronRoutes } from "../../Config/Routes";
import { ScraperApiHelperContext } from "../../contexts";
import NotFound from "../404/404";
import { usePreferedColorScheme } from "../AdminLTE3/DarkMode";
import Footer from "../AdminLTE3/Footer";
import MainHeader from "../AdminLTE3/MainHeader";
import MainSidebar from "../AdminLTE3/MainSidebar";
import CelebrationCursorLogic from "../Celebration/CelebrationCursor";
import { ChristmasCursor, ChristmasLights } from "../Celebration/Christmas";
import { HalloweenCursor } from "../Celebration/Halloween";
import { hasPermissions } from "../Utils/Utils";

const REG_TO_CALLBACK = [
	...[false, true, null, undefined].map((k) => ({
		test: new RegExp(`^${k}$`),
		value: k,
	})),
	{
		test: /^[+-]?([0-9]*[.])?[0-9]+$/,
		value: parseFloat,
	},
	{
		test: /^[-+]?\d+$/,
		value: parseInt,
	},
];

/* this function sets real types to stringlike params
 ** year: "2019" -> year: 2019
 */
function applyTypes(object) {
	Object.entries(object).forEach(([key, content]) => {
		if (typeof content === "object") {
			applyTypes(object[key]);
		} else if (Array.isArray(content)) {
			for (let i = 0; i < content.length; ++i) {
				applyTypes(content[i]);
			}
		} else {
			REG_TO_CALLBACK.forEach(({ test, value }) => {
				if (test.test(content)) {
					if (typeof value === "function") {
						object[key] = value(content);
					} else {
						object[key] = value;
					}
				}
			});
		}
	});
}

function Wrapper({ Component, ...props }) {
	if (!Component) return null;
	props.params = useParams();
	props.location = useLocation();
	props.navigate = useNavigate();
	props.query = QS.parse(props.location.search, { ignoreQueryPrefix: true });
	applyTypes(props.query);
	return <Component {...props} />;
}

function routeMappingFromConfig(routes, account) {
	return Object.entries(routes)
		.filter(([, page]) => hasPermissions(page, account))
		.map(([key, route]) => (
			<Route
				key={key}
				path={route.path}
				element={
					<div className="content-wrapper">
						<Wrapper Component={route.component} />
					</div>
				}
			/>
		));
}

function notFound() {
	return (
		<Route
			element={
				<div className="content-wrapper">
					<Wrapper Component={NotFound} />
				</div>
			}
		/>
	);
}

export default function SauronRouter() {
	const preferedColorScheme = usePreferedColorScheme();
	const [colorScheme, setColorScheme] = useState(preferedColorScheme);
	const toggleColorScheme = (value) => {
		const newScheme = value || (colorScheme === "dark" ? "light" : "dark");
		setColorScheme(newScheme);
		localStorage.setItem("colorScheme", newScheme);
		if (newScheme === "dark") {
			document.body.classList.add("dark-mode");
		} else {
			document.body.classList.remove("dark-mode");
		}
	};
	const scraper = useContext(ScraperApiHelperContext);
	const routeComponents = routeMappingFromConfig(
		SauronRoutes,
		scraper.api.getSauronUserInfo()
	);

	useEffect(() => {
		if (colorScheme === "dark") {
			document.body.classList.add("dark-mode");
		}
	}, []);

	return (
		<Suspense fallback={null}>
			<DatesProvider settings={{ locale: "fr" }}>
				<ColorSchemeProvider
					colorScheme={colorScheme}
					toggleColorScheme={toggleColorScheme}
				>
					<MantineProvider
						theme={{ colorScheme }}
						withGlobalStyles
						withNormalizeCSS
					>
						<Router>
							<Wrapper Component={MainHeader} />
							<Wrapper Component={MainSidebar} />
							<ChristmasLights />
							<Routes>
								{routeComponents}
								{notFound()}
							</Routes>
							<Footer />
							<CelebrationCursorLogic>
								<ChristmasCursor />
								<HalloweenCursor />
							</CelebrationCursorLogic>
						</Router>
					</MantineProvider>
				</ColorSchemeProvider>
			</DatesProvider>
		</Suspense>
	);
}
