import {
	faCheck,
	faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { degrade } from "./Utils";

export function AlertBadge({ max_alerts, alerts }) {
	return (
		<span
			style={{
				backgroundColor: degrade(max_alerts, alerts.length),
				display: "block",
				width: 80,
				fontSize: "12px",
				fontWeight: 700,
				textAlign: "center",
				whiteSpace: "nowrap",
				verticalAlign: "top",
				borderRadius: "10px",
				paddingTop: 1,
				paddingBottom: 1,
			}}
			className="text-white"
			data-tooltip-id="alerts-row-tooltip"
			data-tooltip-place="right"
			data-tooltip-html={alerts
				.map((alert) => `${alert.capfirst()}`)
				.join("<br />")}
		>
			<FontAwesomeIcon
				icon={alerts.length ? faExclamationTriangle : faCheck}
				size="sm"
			/>
			<span className="ml-1">{alerts.length}</span>
		</span>
	);
}
