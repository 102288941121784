import { Component } from "react";

import { t } from "../../Config/i18n";

export class SwitchCelebration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			celebrationSwitch: true,
		};
	}

	componentDidMount() {
		let disableCelebration = localStorage.getItem("disableCelebration");
		if (disableCelebration) {
			disableCelebration = disableCelebration === "true";
		} else {
			disableCelebration = false;
			localStorage.setItem("disableCelebration", "false");
		}
		this.setState({
			celebrationSwitch: !disableCelebration,
		});
	}

	handleSwitchChange = () => () => {
		const { celebrationSwitch } = this.state;
		if (celebrationSwitch)
			localStorage.setItem("disableCelebration", "true");
		else localStorage.setItem("disableCelebration", "false");
		this.setState({
			celebrationSwitch: !celebrationSwitch,
		});
		window.location.reload(false);
	};

	render() {
		const { celebrationSwitch } = this.state;
		if (import.meta.env.VITE_CELEBRATION !== "none")
			return (
				<div className="ml-2 custom-control custom-switch">
					<input
						type="checkbox"
						className="custom-control-input"
						id="celebrationSwitch"
						checked={celebrationSwitch}
						onChange={this.handleSwitchChange()}
						readOnly
					/>
					<label
						className="custom-control-label text-white"
						htmlFor="celebrationSwitch"
					>
						{t("Festive theme")}
					</label>
				</div>
			);
		return null;
	}
}
