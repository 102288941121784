import { useInfiniteQuery, useQueries, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useSemesters = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["master", "filters_options", "semesters"],
		queryFn: () => scraper.getMasterSemesters(),
		select: (data) => data.toFilterOptions(),
	});
};

export const useReferents = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["master", "filters_options", "referents"],
		queryFn: () => scraper.getReferents(),
		select: (data) =>
			data.map((referent) => {
				return {
					label: referent
						.replace("@epitech.eu", "")
						.split(".")
						.join(" ")
						.title(),
					value: referent,
				};
			}),
	});
};

export const useStudentsAlertsStatsQuery = (promotions) => {
	const scraper = useContext(ScraperApiHelperContext);
	const curriculum = "master";

	return useQueries({
		queries: promotions.map((promotion) => ({
			queryKey: [
				"master",
				"stats",
				"students_alerts_stats",
				promotion,
				curriculum,
			],
			queryFn: () =>
				scraper.students.getAlertsByCity(
					"FR/PAR",
					"epitech",
					promotion,
					curriculum
				),
		})),
	});
};

export const useRoadblocksAlertsStatsQuery = (promotions) => {
	const scraper = useContext(ScraperApiHelperContext);
	const curriculum = "master";
	return useQueries({
		queries: promotions.map((promotion) => ({
			queryKey: [
				"master",
				"stats",
				"roadblocks_alerts_stats",
				"FR/PAR",
				promotion,
				curriculum,
			],
			queryFn: () =>
				scraper.students.getRoadblocksAlertStats(
					"FR/PAR",
					promotion,
					curriculum
				),
		})),
	});
};

export const useStudentsRoadblocksQuery = (
	semesters,
	promotions,
	referents,
	areRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	const curriculum = "master";
	return useInfiniteQuery({
		queryKey: [
			"master",
			"table_data",
			semesters,
			promotions,
			referents,
			curriculum,
		],
		queryFn: ({ pageParam }) =>
			scraper.students.getRoadblocks(
				pageParam,
				"FR/PAR",
				promotions,
				curriculum,
				false,
				semesters,
				referents
			),
		initialPageParam: 0,
		getNextPageParam,
		enabled: areRequestEnabled,
	});
};
