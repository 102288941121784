import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, ListGroup } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { t } from "../../../Config/i18n";
import { Box } from "../../AdminLTE3/Box";
import EpitechAvatar from "../../Utils/EpitechAvatar";
import InternshipEntry from "./InternshipEntry";
import ListEntry from "./ListEntry";
import RedirectButton from "./RedirectButton";
import RoadblocksEntries from "./RoadblocksEntries";

export default function StudentOverview({ student, roadblocksQuery }) {
	const { data: roadblocks, isFetching: areRoadblocksLoading } =
		roadblocksQuery;

	const additionalInfosFormatter = (key, value) => {
		switch (key) {
			case "bachelor_city":
				return value.name;
			default:
				return value;
		}
	};

	return (
		<div>
			<Box>
				<Card.Header
					className="d-flex justify-content-center align-items-center"
					style={{ position: "relative" }}
				>
					<EpitechAvatar
						login={student.login}
						className="img elevation-1"
						style={{ borderRadius: "10px", maxWidth: "25%" }}
						onErrorRender={
							<FontAwesomeIcon
								icon={faUserTie}
								data-place="bottom"
								data-tooltip-id="student-overview-tooltip"
								data-tooltip-content={t(
									"no profile picture found"
								).capfirst()}
								size="10x"
							/>
						}
						avatar="profile"
					/>
					<div className="ml-5">
						<div>
							<h5 className="text-center">{`${student.firstname} ${student.lastname}`}</h5>
						</div>
						<div>
							<h6 className="text-center">
								{student.promo.city.name}
							</h6>
						</div>
					</div>
				</Card.Header>
				<Card.Body>
					<ListGroup variant="flush">
						<ListEntry
							label="curriculum"
							value={student.promo.cursus.code}
						/>
						<ListEntry
							label="promotion"
							value={student.promo.promotion_year}
						/>
						<ListEntry label="G.P.A." value={student.gpa} />
						{Object.entries(student.additional_infos).map(
							([label, value]) => (
								<ListEntry
									key={`list_entry_${label}`}
									label={label.replace(/_/g, " ")}
									value={
										typeof value === "string"
											? value
											: additionalInfosFormatter(
													label,
													value
												)
									}
								/>
							)
						)}
						<InternshipEntry student={student} />
						<RedirectButton student={student} />
					</ListGroup>
				</Card.Body>
				<Tooltip id="student-overview-tooltip" />
			</Box>
			<Box>
				<Card.Header
					className="text-center"
					style={{ position: "relative" }}
				>
					{t("roadblocks").capfirst()}
				</Card.Header>
				<Card.Body>
					<ListGroup variant="flush">
						<RoadblocksEntries
							roadblocks={roadblocks?.roadblocks}
							isLoading={areRoadblocksLoading}
						/>
					</ListGroup>
				</Card.Body>
				<Tooltip id="student-overview-roadblocks-tooltip" />
			</Box>
		</div>
	);
}
