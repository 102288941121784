import {
	faChalkboardTeacher,
	faChartBar,
	faChartLine,
	faCity,
	faCode,
	faCube,
	faDashboard,
	faEdit,
	faExclamationTriangle,
	faFile,
	faFileSignature,
	faGraduationCap,
	faInfoCircle,
	faKey,
	faLanguage,
	faMagnifyingGlassChart,
	faMarker,
	faScrewdriver,
	faUser,
	faUsers,
} from "@fortawesome/free-solid-svg-icons";

import Bachelor from "../Components/Bachelor/Bachelor";
import Cheaters from "../Components/Cheaters/Cheaters";
import Cities from "../Components/Cities/Cities";
import Configurations from "../Components/Configurations/Configurations";
import Dashboard from "../Components/Dashboard/Dashboard";
import Diversity from "../Components/Diversity/Diversity";
import Evaluations from "../Components/Evaluations/Evaluations";
import Home from "../Components/Home/Home";
import InternshipPartTime from "../Components/Internship-PartTime/Internship-PartTime";
import InternshipProgression from "../Components/InternshipProgression/InternshipProgression";
import IntuituPersonae from "../Components/IntuituPersonae/IntuituPersonae";
import InvestmentOverview from "../Components/InvestmentOverview/InvestmentOverview";
import InvestmentReports from "../Components/InvestmentReports/InvestmentReports";
import JwtManager from "../Components/JwtManager/JwtManager";
import Master from "../Components/Master/Master";
import ModuleAlerts from "../Components/ModuleAlerts/ModuleAlerts";
import ModuleValidation from "../Components/ModuleValidation/ModuleValidation";
import Logout from "../Components/Oauth2/Logout";
import ProfessionalCommunication from "../Components/ProfessionalCommunication/ProfessionalCommunication";
import ScrapingHistory from "../Components/ScrapingHistory/ScrapingHistory";
import Student from "../Components/Student/Student";
import StudentPresence from "../Components/StudentPresence/StudentPresence";
import TepitechAlerts from "../Components/TepitechAlerts/TepitechAlerts";
import TepitechProgression from "../Components/TepitechProgression/TepitechProgression";
import Tesseract from "../Components/Tesseract/Tesseract";
import TesseractConfiguration from "../Components/TesseractConfiguration/TesseractConfiguration";
import Transcripts from "../Components/Transcripts/Transcripts";

export const SauronRoutes = {
	logout: {
		path: "/logout",
		component: Logout,
	},
	home: {
		path: "/",
		component: Home,
	},
	student: {
		path: "/student/:login",
		component: Student,
	},
	dashboard: {
		label: "dashboard",
		path: "/dashboard",
		icon: faDashboard,
		component: Dashboard,
		authorized_groups: ["pedago", "admin"],
	},
	bachelor: {
		label: "bachelor overview",
		path: "/bachelor",
		icon: faUsers,
		component: Bachelor,
		authorized_groups: ["pedago", "admin"],
	},
	master: {
		label: "CEI overview",
		path: "/master",
		icon: faGraduationCap,
		component: Master,
		authorized_groups: ["cei", "admin"],
	},
	cities: {
		label: "city overview",
		path: "/cities",
		icon: faCity,
		component: Cities,
		authorized_groups: ["admin", "pedago"],
	},
	investment_reports: {
		label: "investment report",
		path: "/investment_reports",
		icon: faFileSignature,
		component: InvestmentReports,
		authorized_groups: ["admin", "pedago"],
	},
	student_presences: {
		label: "student presences",
		path: "/student_presences",
		icon: faUser,
		component: StudentPresence,
		authorized_groups: ["pedago", "admin"],
	},
	module_alerts: {
		label: "module alerts",
		path: "/module_alerts",
		icon: faExclamationTriangle,
		component: ModuleAlerts,
		authorized_groups: ["pedago", "admin"],
	},
	tepitech_alerts: {
		label: "Tepitech alerts",
		path: "/tepitech_alerts",
		icon: faLanguage,
		component: TepitechAlerts,
		authorized_groups: ["english", "pedago", "admin"],
	},
	internship_part_time: {
		label: "internships and part-time",
		icon: faFileSignature,
		path: "/contracts_overview",
		component: InternshipPartTime,
		authorized_groups: ["pedago", "admin"],
	},
	internship_progression: {
		label: "internships progression",
		icon: faChartLine,
		path: "/internships_progression",
		component: InternshipProgression,
		authorized_groups: ["pedago", "admin"],
	},
	module_validations: {
		icon: faChartBar,
		label: "module validations",
		path: "/module_validations",
		component: ModuleValidation,
		authorized_groups: ["pedago", "admin"],
	},
	tepitech_progress: {
		label: "Tepitech progress",
		path: "/tepitech_progress",
		icon: faChartLine,
		component: TepitechProgression,
		authorized_groups: ["english", "pedago", "admin"],
	},
	diversity_overview: {
		label: "Diversity - overview",
		path: "/diversity_overview",
		icon: faChalkboardTeacher,
		component: Diversity,
		authorized_groups: ["pedago", "admin"],
	},
	investment_overview: {
		label: "investment overview",
		path: "/investment_overview",
		icon: faMagnifyingGlassChart,
		component: InvestmentOverview,
		authorized_groups: ["pedago", "admin"],
	},
	tesseract: {
		label: "tesseract",
		path: "/tesseract",
		icon: faCube,
		component: Tesseract,
		authorized_groups: ["pedago", "admin"],
	},
	tesseract_configuration: {
		label: "tesseract configuration",
		path: "/tesseract_config",
		icon: faScrewdriver,
		component: TesseractConfiguration,
		authorized_groups: ["units_responsible", "admin"],
	},
	evaluations: {
		label: "evaluations",
		path: "/evaluations",
		icon: faEdit,
		component: Evaluations,
		authorized_groups: ["pedago", "admin"],
	},
	intuitu_personae: {
		label: "intuitu_personae",
		path: "/intuitu_personae",
		icon: faUsers,
		component: IntuituPersonae,
		authorized_groups: ["dpr", "admin"],
	},
	transcripts: {
		label: "transcripts overview",
		path: "/transcripts",
		icon: faFile,
		component: Transcripts,
		authorized_groups: ["pedago", "admin"],
	},
	cheaters: {
		label: "cheaters",
		path: "/cheaters",
		icon: faCode,
		component: Cheaters,
		authorized_groups: ["pedago", "admin"],
	},
	professional_communication: {
		label: "Professional Communication",
		path: "/validations/professional_communication",
		icon: faMarker,
		component: ProfessionalCommunication,
		authorized_groups: ["pedago", "admin"],
	},
	scraping_history: {
		label: "scraping history",
		path: "/scraping_history",
		icon: faInfoCircle,
		component: ScrapingHistory,
		authorized_groups: ["admin"],
	},
	admin_panel: {
		label: "configurations",
		path: "/admin_panel",
		icon: faScrewdriver,
		component: Configurations,
		authorized_groups: ["admin"],
	},
	jwt_manager: {
		label: "JWT manager",
		path: "/jwt_manager",
		icon: faKey,
		component: JwtManager,
		authorized_groups: ["admin"],
	},
};
