import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import LoginRow from "../Utils/LoginRow";
import InvestmentAlert from "./InvestmentAlert";
import InvestmentStudentChart from "./InvestmentStudentChart";

export default function DataTable({ data, stats, isFetching }) {
	const columns = [
		{
			id: "alert",
			accessorFn: (row) => row.alert,
			header: t("alert").capfirst(),
			Cell: ({ row }) => (
				<InvestmentAlert alertLabel={row.original.alert} />
			),
			size: 100,
			enableSorting: true,
		},
		{
			id: "login",
			accessorFn: (row) => row.login,
			header: "Login",
			size: 200,
			enableSorting: true,
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.login}
					pageTooltip="investment-student-tooltip"
				/>
			),
		},
		{
			id: "promotion",
			accessorFn: (row) => row.promotion,
			header: t("promotion").capfirst(),
			size: 100,
			enableSorting: true,
		},
		{
			id: "curriculum",
			accessorFn: (row) => row.curriculum,
			header: t("curriculum").capfirst(),
			size: 100,
			enableSorting: true,
		},
		{
			id: "city",
			accessorFn: (row) => row.city,
			header: t("city").capfirst(),
			size: 100,
			enableSorting: true,
		},
		{
			id: "logtime_count",
			accessorFn: (row) => row.logtime_count,
			Cell: ({ row }) => `${row.original.logtime_count}h`,
			header: t("logtime").capfirst(),
			size: 100,
			enableSorting: true,
		},
		// {
		// 	id: "logtime_ratio",
		// 	accessorFn: (row) => `${row.logtime_ratio}`,
		// 	header: t("logtime score").capfirst(),
		// 	size: 100,
		// 	enableSorting:true,
		// },
		{
			id: "presences_count",
			accessorFn: (row) => row.presences_count,
			header: t("presences").capfirst(),
			size: 100,
			enableSorting: true,
		},
		{
			id: "commits_count",
			accessorFn: (row) => row.commits_count,
			header: t("commits").capfirst(),
			size: 100,
			enableSorting: true,
		},
		{
			id: "lines_count",
			accessorFn: (row) => row.lines_count,
			header: t("lines of code").capfirst(),
			size: 100,
			enableSorting: true,
		},
		{
			id: "score",
			accessorFn: (row) => row.score,
			header: t("score").capfirst(),
			Cell: ({ row }) => <b>{row.original.score}</b>,
			size: 100,
			enableSorting: true,
		},
		{
			id: "stats",
			header: t("stats").capfirst(),
			size: 250,
			Cell: ({ row }) => (
				<div style={{ height: 200 }}>
					<InvestmentStudentChart
						data={row.original.stats}
						student={row.original.student}
						stats={stats}
					/>
				</div>
			),
		},
	];

	return (
		<SauronMantineDataTable
			columns={columns}
			data={data}
			isFetching={isFetching}
			title={t("investment").capfirst()}
			subtitle={t("per students")}
			isCard
			tableProps={{
				initialState: {
					columnVisibility: {
						stats: false,
					},
				},
			}}
		/>
	);
}
