import { useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { getCurrentAcademicYear } from "../Utils/Utils";
import MasterDataTable from "./MasterDataTable";
import { useStudentsRoadblocksQuery } from "./Queries";

export default function MasterDataTableContainer({
	semesters,
	promotions,
	referents,
	areRequestEnabled,
}) {
	const currentYear = getCurrentAcademicYear();
	const promos =
		promotions && promotions.length > 0
			? promotions
			: [currentYear, currentYear + 1, currentYear + 2];
	const {
		data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		isFetching,
		isLoading,
		error,
	} = useStudentsRoadblocksQuery(
		semesters,
		promos,
		referents,
		areRequestEnabled
	);
	if (areRequestEnabled && !isFetchingNextPage && hasNextPage)
		fetchNextPage();
	const requestData = data?.pages.flatMap((page) => page.data.results) ?? [];

	const tableData = useMemo(() => {
		if (!requestData || !areRequestEnabled) return [];
		return requestData.map((apiRow, idx) => ({
			id: idx,
			alerts: apiRow.alerts,
			login: apiRow.student.login,
			promotion: apiRow.student.promo.promotion_year,
			semester: apiRow.student.semester,
			forecast:
				apiRow.roadblocks.total_credits.obtained +
				apiRow.roadblocks.total_credits.inprogress +
				apiRow.roadblocks.part_time.credits,
			available:
				apiRow.roadblocks.total_credits.obtained +
				apiRow.roadblocks.total_credits.inprogress,
			credits: apiRow.roadblocks.total_credits.obtained,
			in_progress: apiRow.roadblocks.total_credits.inprogress,
			failed: apiRow.roadblocks.total_credits.failed,
			referent: apiRow.roadblocks.referent.login,
			eip4: apiRow.roadblocks.tech4_eip.state,
			eip4_credits: apiRow.roadblocks.tech4_eip.credits,
			eip5: apiRow.roadblocks.tech5_eip.state,
			eip5_credits: apiRow.roadblocks.tech5_eip.credits,
			part_time: apiRow.roadblocks.part_time.state,
			part_time_credits: apiRow.roadblocks.part_time.credits,
			free_project: apiRow.roadblocks.free_project.state,
			free_project_credits: apiRow.roadblocks.free_project.credits,
			modules_in_progress: apiRow.roadblocks.units.inprogress,
			modules_failed: apiRow.roadblocks.units.failed,
			tech4_destination:
				apiRow.student.additional_infos.tech4_destination,
			bachelor_city:
				apiRow.student.additional_infos.bachelor_city?.name ?? "N/A",
			nb_absences: apiRow.roadblocks.nb_absences.count,
		}));
	}, [requestData]);
	const exportConfig = {
		alerts: "alerts.length",
		login: "login",
		promotion: "promotion",
		semester: "semester",
		forecast: "forecast",
		available: "available",
		credits: "obtained",
		in_progress: "inprogress",
		failed: "failed",
		referent: "referent",
		eip4: "eip4",
		eip4_credits: "eip4_credits",
		eip5: "eip5",
		eip5_credits: "eip5_credits",
		part_time: "part_time",
		part_time_credits: "part_time_credits",
		free_project: "free_project",
		free_project_credits: "free_project_credits",
		modules_in_progress: "modules_in_progress",
		modules_failed: "modules_failed",
		tech4_destination: "tech4_destination",
		bachelor_city: "bachelor_city",
		nb_absences: "nb_absences",
	};

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Master Data Table: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<MasterDataTable
			data={tableData}
			exportConfig={{
				columns: exportConfig,
				title: "master_roadblocks",
			}}
			isFetching={isFetching}
			isLoading={isLoading}
		/>
	);
}
