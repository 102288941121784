import { useEffect } from "react";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import { t } from "../../Config/i18n";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { formatDataForSuccessCharts } from "../Utils/FormatDataForCharts";
import { getAlertLabels } from "../Utils/Labels";
import { getCurrentAcademicYear } from "../Utils/Utils";
import { useRoadblocksAlertsStatsQuery } from "./Queries";

export default function MasterSuccessChart({ promotions }) {
	const currentYear = getCurrentAcademicYear();
	const alertLabels = getAlertLabels("master");
	const promos =
		promotions && promotions.length > 0
			? promotions
			: [currentYear, currentYear + 1, currentYear + 2];
	const results = useRoadblocksAlertsStatsQuery(promos);
	const isFetching = results.some((query) => query.isFetching);
	const error = results.find((query) => query.error !== null);
	const promotionsStats = results.map((query) => query.data?.data);
	const sums = {
		France: {},
	};
	promotionsStats.forEach((promotionStat) => {
		if (!promotionStat || Object.keys(promotionStat).length === 0) return;

		Object.keys(promotionStat.France).forEach((property) => {
			sums.France[property] = { OK: 0, KO: 0, in_progress: 0 };
			sums.France[property].OK += promotionStat.France[property].OK;
			sums.France[property].KO += promotionStat.France[property].KO;
			sums.France[property].in_progress +=
				promotionStat.France[property].in_progress;
		});
	});
	const successData = formatDataForSuccessCharts(
		sums,
		alertLabels,
		"National"
	);

	useEffect(() => {
		if (!error) return;
		toast.error(
			`Master Success Chart: ${t("An error has occured")}: ${
				error.message
			}!`,
			{
				theme: "colored",
				autoClose: 10000,
			}
		);
	}, [error]);

	return (
		<SauronChartsContainer
			title={t("success rate").capfirst()}
			subtitle={`${t("by")} ${t("roadblocks")}`}
			isLoading={isFetching}
		>
			{successData ? (
				<Bar data={successData} options={defaultBarOptions} />
			) : null}
		</SauronChartsContainer>
	);
}
