import { Bar } from "react-chartjs-2";
import { t } from "../../Config/i18n";
import SauronBox from "../Sauron/SauronBox";
import { SauronChartsContainer } from "../Sauron/SauronChartsContainer";
import { defaultBarOptions } from "../Utils/DefaultConst";
import { sortObjectByKeysWithGlobalAtEnd } from "../Utils/Utils";
import NoModuleSpecified from "./NoModuleSpecified";

const getFlagConfiguration = () => ({
	medal: { color: "#2ecc71", label: t("medal") },
	remarkable: { color: "#2980b9", label: t("remarkable") },
	difficulty: { color: "#e67e22", label: t("difficulty") },
	ghost: { color: "#c0392b", label: t("ghost") },
	no_flag: { color: "#bdc3c7", label: t("no flag") },
});

const options = {
	...defaultBarOptions,
	...{
		legend: {
			reverse: true,
		},
	},
};

function NoDataAvailable() {
	return (
		<div className="text-center">
			<em className="text-gray">
				{t("no flags obtainable for this module").capfirst()}
			</em>
		</div>
	);
}

export default function FlagsStats({
	flagsStats,
	isLoading,
	areRequestEnabled,
}) {
	const computeData = () => {
		if (!flagsStats || !("National" in flagsStats)) return null;
		const FLAG_CONFIGURATION = getFlagConfiguration();
		const sortedStats = sortObjectByKeysWithGlobalAtEnd(flagsStats);
		const possibleFlags = Object.keys(sortedStats.National.flags);
		const instances = Object.keys(sortedStats);
		const data = {
			labels: instances,
			datasets: [],
		};
		possibleFlags.forEach((flag) => {
			const flagData = instances.map(
				(instance) => sortedStats[instance].flags[flag] ?? 0
			);
			data.datasets.push({
				data: flagData,
				label: FLAG_CONFIGURATION[flag].label.capfirst(),
				backgroundColor: FLAG_CONFIGURATION[flag].color,
			});
		});
		return data;
	};
	const data = computeData();
	if (!areRequestEnabled) {
		return (
			<SauronBox title={t("flags").capfirst()} isLoading={isLoading}>
				<NoModuleSpecified />
			</SauronBox>
		);
	}
	return (
		<SauronChartsContainer
			title={t("flags").capfirst()}
			isLoading={isLoading}
		>
			{data ? <Bar options={options} data={data} /> : <NoDataAvailable />}
		</SauronChartsContainer>
	);
}
