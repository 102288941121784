import { useContext } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte";

import { SauronRoutes } from "../../Config/Routes";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { getSauronLogo, hasPermissions } from "../Utils/Utils";

const PAGES = {
	"full overview": [
		SauronRoutes.dashboard,
		SauronRoutes.bachelor,
		SauronRoutes.master,
		SauronRoutes.cities,
		SauronRoutes.investment_overview,
		SauronRoutes.intuitu_personae,
		SauronRoutes.investment_reports,
	],
	"module validations": [
		SauronRoutes.tesseract,
		SauronRoutes.tesseract_configuration,
		SauronRoutes.evaluations,
		SauronRoutes.cheaters,
		SauronRoutes.professional_communication,
	],
	"alerts overview": [
		SauronRoutes.transcripts,
		SauronRoutes.student_presences,
		SauronRoutes.module_alerts,
		SauronRoutes.tepitech_alerts,
		SauronRoutes.internship_part_time,
		SauronRoutes.diversity_overview,
	],
	"stats": [
		SauronRoutes.module_validations,
		SauronRoutes.tepitech_progress,
		SauronRoutes.internship_progression,
	],
	"administration": [
		SauronRoutes.scraping_history,
		SauronRoutes.admin_panel,
		SauronRoutes.jwt_manager,
	],
};

export default function MainSidebar({ location }) {
	const logo = getSauronLogo();
	const route = location.pathname;
	const buildPages = (key) => {
		const scraper = useContext(ScraperApiHelperContext);
		const pages = PAGES[key].filter((page) =>
			hasPermissions(page, scraper.api.getSauronUserInfo())
		);
		if (pages.length === 0) {
			return null;
		}
		const layouts = [
			<li
				className="nav-header text-muted font-weight-bold"
				style={{ fontSize: 16 }}
				key={key}
			>
				{t(key).capfirst()}
			</li>,
		];
		return layouts.concat(
			pages.map((page) => (
				<li className="nav-item" key={`${key}.${page.path}`}>
					<Link
						className={`nav-link ${
							route === page.path ? "active" : ""
						}`}
						to={page.path}
					>
						<FontAwesomeIcon
							style={{
								verticalAlign: "middle",
								marginRight: "5px",
							}}
							icon={page.icon}
							size="sm"
						/>
						<p style={{ fontSize: "14px" }} className="ml-1">
							{t(page.label).capfirst()}
						</p>
					</Link>
				</li>
			))
		);
	};

	return (
		<aside
			className="main-sidebar sidebar-dark-primary elevation-5"
			id="main_sidebar"
		>
			<Link to="." className="brand-link">
				<img
					src={logo}
					alt="Sauron Logo"
					className="brand-image img-circle elevation-5"
					style={{
						opacity: ".8",
						height: "32px",
						width: "32px",
					}}
				/>
				<span
					className="brand-text font-weight-bold"
					style={{ verticalAlign: "baseline" }}
				>
					Sauron
				</span>
			</Link>
			<div className="sidebar">
				<nav>
					<ul
						className="nav nav-pills nav-sidebar flex-column"
						role="menu"
					>
						{/* The followin <li> is used to passby the adminlte padding for first <li> */}
						<li
							className="nav-header mx-0"
							style={{ display: "none" }}
						/>
						{Object.keys(PAGES).map(buildPages)}
					</ul>
				</nav>
			</div>
		</aside>
	);
}
