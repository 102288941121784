import { useInfiniteQuery, useQueries, useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";
import { getNextPageParam } from "../Utils/Utils";

export const useCurriculumsQuery = () => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["bachelor", "filters_options", "curriculums"],
		queryFn: () => scraper.getPromotions("bachelor"),
		select: (data) => data.toFilterOptions(),
	});
};

export const useRoadblocksAlertsStatsQuery = (
	city,
	promotion,
	curriculum,
	projection
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: [
			"bachelor",
			"stats",
			"roadblocks_alerts_stats",
			city,
			promotion,
			curriculum,
			projection,
		],
		queryFn: () =>
			scraper.students.getRoadblocksAlertStats(
				city,
				promotion,
				curriculum,
				projection
			),
	});
};

export const useStudentsAlertsStatsQuery = (
	cities,
	promotions,
	curriculum,
	projection
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQueries({
		queries: cities.flatMap((location) =>
			promotions.map((promotion) => ({
				queryKey: [
					"bachelor",
					"stats",
					"students_alerts_stats",
					location.label,
					promotion,
					curriculum,
					projection,
				],
				queryFn: () =>
					scraper.students.getAlertsByCity(
						location.value,
						"epitech",
						promotion,
						curriculum,
						projection
					),
			}))
		),
	});
};

export const useStudentsRoadblocksQuery = (
	city,
	promotion,
	curriculum,
	projection,
	isRequestEnabled
) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useInfiniteQuery({
		queryKey: [
			"bachelor",
			"table_data",
			"students_roadblocks",
			city,
			promotion,
			curriculum,
			projection,
		],
		queryFn: ({ pageParam }) =>
			scraper.students.getRoadblocks(
				pageParam,
				city,
				promotion,
				curriculum,
				projection
			),
		initialPageParam: 0,
		getNextPageParam,
		enabled: isRequestEnabled,
	});
};
