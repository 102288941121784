import { getCurrentAcademicYear } from "../../Components/Utils/Utils";

export default class Projects {
	constructor(api) {
		this.api = api;
	}

	getAvailableYears() {
		return this.api
			.processRequest("GET", "/projects/available_years")
			.then((res) => res.data);
	}

	getAvailableProjects(
		offset = 0,
		year = getCurrentAcademicYear(),
		module_code = null,
		limit = 200
	) {
		return this.api
			.processRequest("GET", "/projects", {
				params: { year, module_code, offset, limit },
			})
			.then((res) => res.data);
	}

	getAvailableProjectInstances(year, module_code, slug) {
		return this.api
			.processRequest("GET", "/projects/instances", {
				params: { year, module_code, slug },
			})
			.then((res) => res.data);
	}
}
