import { Card, Col, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { t } from "../../../Config/i18n";
import { Box, BoxTitle } from "../../AdminLTE3/Box";
import { degrade } from "../../Utils/Utils";
import { useAlertsPerCityQuery } from "../Queries";

const COLORS = Array(8)
	.fill(0)
	.map((_, idx) => degrade(7, idx));

function getDoughnutOptions(displayLegend) {
	return {
		maintainAspectRatio: false,
		legend: {
			display: false,
		},
		plugins: {
			legend: {
				display: displayLegend,
			},
		},
	};
}

export function AlertsDoughnut({ alerts, displayLegend = false }) {
	const labels = Object.keys(alerts).map(
		(nbAlerts) => `${t("alertWithCount", { count: Number(nbAlerts) })}`
	);
	const options = getDoughnutOptions(displayLegend);
	if (labels.length === 0) {
		return (
			<p className="text-red">
				{t("there is no available data").capfirst()}
			</p>
		);
	}
	const data = {
		labels,
		datasets: [
			{
				data: Object.values(alerts),
				backgroundColor: COLORS.slice(0, labels.length),
			},
		],
	};
	return <Doughnut height={150} width={200} data={data} options={options} />;
}

export function CityPromotionStatus({
	cityOption,
	promotionOption,
	displayCityName,
}) {
	const { label: city, value: cityCode } = cityOption;
	const { label: promotion, value: promotionYear } = promotionOption;
	const { data = {} } = useAlertsPerCityQuery(cityCode, promotionYear);
	return (
		<div style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle
					title={`${
						displayCityName ? `${city.capfirst()} - ` : ""
					}${promotion}`}
				/>
			</Card.Header>
			<Card.Body style={{ alignSelf: "center" }}>
				<Row className="mb-3" style={{ justifyContent: "center" }}>
					<div style={{ height: 150 }}>
						<AlertsDoughnut alerts={data} />
					</div>
				</Row>
			</Card.Body>
		</div>
	);
}

function NeedsPromotionMessage() {
	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("promotions status").capfirst()} />
			</Card.Header>
			<Card.Body>
				<Col key="empty_promotion_field_message">
					<p>
						{t(
							"you must specify promotion(s) to load these graphs"
						).capfirst()}
					</p>
				</Col>
			</Card.Body>
		</Box>
	);
}

export default function CitiesPromotionsStatusCard({ cities, promotions }) {
	const citiesWithNational = [...cities, { label: "Global", value: null }];
	if (promotions.length === 0) return <NeedsPromotionMessage />;
	return (
		<Box style={{ height: "100%" }}>
			<Card.Header>
				<BoxTitle title={t("promotions status").capfirst()} />
			</Card.Header>
			<Card.Body>
				<Row md={promotions.length}>
					{citiesWithNational.flatMap((city) =>
						promotions.map((promotion) => (
							<CityPromotionStatus
								key={`Promotion_status_${city.value}_${promotion.label}`}
								cityOption={city}
								promotionOption={promotion}
								displayCityName
							/>
						))
					)}
				</Row>
			</Card.Body>
		</Box>
	);
}
