import { useEffect, useState } from "react";
import {
	Form,
	FormControl,
	FormGroup,
	NavItem,
	NavLink,
	Navbar,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";

import {
	faBars,
	faPowerOff,
	faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte";

import { SauronRoutes } from "../../Config/Routes";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import { SwitchCelebration } from "../Celebration/Celebration";
import { ChristmasMusic } from "../Celebration/Christmas";
import EpitechAvatar from "../Utils/EpitechAvatar";
import SwitchDarkMode from "./DarkMode";
import { useSearchStudents } from "./Queries";
import StudentSearchModal from "./StudentSearchModal";

export default function MainHeader({ navigate }) {
	const [search, setSearch] = useState("");
	const [students, setStudents] = useState(null);
	const [submittedSearch, setSubmittedSearch] = useState(null);
	const [studentModalOpened, setStudentModalOpened] = useState(false);
	const { data } = useSearchStudents(submittedSearch);

	const onSearchChange = (event) => {
		setSearch(event.target.value);
	};

	const onSubmit = (event) => {
		if (event.key === "Enter" && search.length > 0) {
			event.preventDefault();
			setSubmittedSearch(search);
		}
	};

	useEffect(() => {
		if (data) {
			if (data.results.length === 1)
				navigate(
					SauronRoutes.student.path.replace(
						":login",
						data.results[0].login
					)
				);
			else {
				setStudents(data.results);
				setStudentModalOpened(true);
			}
		}
		setSubmittedSearch(null);
	}, [data]);

	return (
		<Navbar className="main-header navbar-expand navbar-dark navbar-black">
			<ul className="navbar-nav">
				<NavItem>
					<NavLink data-widget="pushmenu" role="button">
						<FontAwesomeIcon icon={faBars} color="white" />
					</NavLink>
				</NavItem>
			</ul>
			<SwitchDarkMode />
			<SwitchCelebration />
			<ChristmasMusic />
			<ScraperApiHelperContext.Consumer>
				{(scraper) => {
					if (scraper.api.getSauronUserInfo().roles.length === 0)
						return null;
					return (
						<Form inline className="navbar-nav ml-auto">
							<FontAwesomeIcon
								icon={faUserGraduate}
								className="mr-2"
								size="2x"
								color="white"
							/>
							<FormGroup>
								<FormControl
									type="search"
									className="form-control-sidebar mr-1"
									placeholder={t(
										"search for a student"
									).capfirst()}
									style={{ fontSize: "15px" }}
									onChange={onSearchChange}
									value={search}
									onKeyDown={onSubmit}
									required
								/>
							</FormGroup>
						</Form>
					);
				}}
			</ScraperApiHelperContext.Consumer>
			<ScraperApiHelperContext.Consumer>
				{(scraper) => {
					const msal_account = scraper.api.getMicrosoftAccountInfo();
					return (
						<div
							className="navbar-nav ml-auto"
							style={{ alignItems: "center" }}
						>
							<EpitechAvatar
								login={
									msal_account
										? msal_account.idTokenClaims.email
										: ""
								}
								className="elevation-2 text-light img-circle"
								style={{ height: 40 }}
							/>
							<Navbar.Text
								className="navbar-text"
								data-place="bottom"
								data-tooltip-id="tooltip-main-header"
								data-tooltip-content={
									msal_account
										? msal_account.idTokenClaims.email
										: ""
								}
								style={{ marginLeft: "10px" }}
							>
								{msal_account
									? msal_account.name.title()
									: null}
							</Navbar.Text>
							<Link
								className="btn btn-danger elevation-2"
								to="logout"
								style={{
									marginLeft: "10px",
									marginRight: "15px",
								}}
								data-place="bottom"
								data-tooltip-id="tooltip-main-header"
								data-tooltip-content={t("logout").capfirst()}
							>
								<FontAwesomeIcon
									icon={faPowerOff}
									color="white"
								/>
							</Link>
						</div>
					);
				}}
			</ScraperApiHelperContext.Consumer>
			<StudentSearchModal
				students={students}
				opened={studentModalOpened}
				onClose={() => setStudentModalOpened(false)}
			/>
			<Tooltip id="tooltip-main-header" />
		</Navbar>
	);
}
