import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import { AlertBadge } from "../Utils/AlertBadge";
import { getAlertLabels } from "../Utils/Labels";
import LoginRow from "../Utils/LoginRow";
import { compare, creditsOverviewSpan, sortByCredits } from "../Utils/Utils";

function getRoadblocksMinRequired(data) {
	const results = {};
	if (!data) return results;
	data.forEach((student) => {
		Object.keys(student.roadblocks).forEach((roadblock_name) => {
			if (!(roadblock_name in results)) {
				results[roadblock_name] =
					student.roadblocks[roadblock_name].min_required;
			}
		});
	});
	if ("solo_stumper" in results || "duo_stumper" in results) {
		const { solo_stumper, duo_stumper, ...rest } = results;
		return { ...rest, solo_stumper, duo_stumper };
	}
	return results;
}

export default function BachelorDataTable({
	data = [],
	city,
	promotion,
	exportConfig,
	isFetching,
	isLoading,
}) {
	const { tepitech, total_credits, ...roadblocksMin } =
		getRoadblocksMinRequired(data);
	const alertLabels = getAlertLabels("bachelor");
	let columns = [
		{
			id: "alerts",
			accessorFn: (row) => row.alerts,
			header: t("alerts").capfirst(),
			Cell: ({ row }) => (
				<AlertBadge
					max_alerts={Object.keys(alertLabels).length + 1}
					alerts={row.original.alerts.map(
						(alert) => alertLabels[alert]
					)}
				/>
			),
			size: 100,
			enableSorting: true,
			sortingFn: (a, b) =>
				-compare(a.original.alerts.length, b.original.alerts.length),
			filterVariant: "range-slider",
			filterFn: (row, _, filterValue) => {
				const min = filterValue[0];
				const max = filterValue[1];
				const nb_alerts = row.original.alerts.length;
				return nb_alerts >= min && nb_alerts <= max;
			},
			mantineFilterRangeSliderProps: {
				max: Object.keys(alertLabels).length,
				min: 0,
				minRange: 1,
			},
		},
		{
			id: "login",
			accessorFn: (row) => row.student.login,
			header: "Login",
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.student.login}
					pageTooltip="bachelor-student-tooltip"
				/>
			),
			size: 200,
			enableSorting: true,
			enableClickToCopy: true,
			filterFn: (row, _, filterValue) =>
				row.original.student.login.includes(filterValue),
		},
		{
			id: "promotion",
			accessorFn: (row) => row.student.promo.promotion_year,
			mantineTableBodyCellProps: { align: "center" },
			header: t("promotion").capfirst(),
			size: 100,
			filterFn: (row, _, filterValue) =>
				row.original.student.promo.promotion_year
					.toString()
					.includes(filterValue),
		},
		{
			id: "curriculum",
			accessorFn: (row) =>
				`${row.student.promo.cursus.code}/${row.student.promo.subpromo}`,
			header: t("curriculum").capfirst(),
			size: 150,
		},
		{
			id: "city",
			accessorFn: (row) => row.student.promo.city.name,
			header: t("city").capfirst(),
			size: 100,
		},
		{
			id: "gpa",
			accessorFn: (row) => row.student.gpa,
			header: t("gpa").capfirst(),
			size: 75,
		},
		{
			id: "total_credits",
			accessorFn: (row) => row.roadblocks.total_credits,
			header: t("credits").capfirst(),
			mantineTableBodyCellProps: { align: "center" },
			Cell: ({ row }) =>
				creditsOverviewSpan(row.original.roadblocks.total_credits),
			size: 125,
			enableSorting: true,
			sortingFn: (a, b) =>
				sortByCredits(
					a.original.roadblocks.total_credits,
					b.original.roadblocks.total_credits
				),
			filterVariant: "range-slider",
			filterFn: (row, colId, filterValue) => {
				const min = filterValue[0];
				const max = filterValue[1];
				const { obtained } = row.original.roadblocks[colId];
				return obtained >= min && obtained <= max;
			},
			mantineFilterRangeSliderProps: {
				max: total_credits,
				min: 0,
				step: 10,
				minRange: 10,
			},
		},
		...Object.keys(roadblocksMin).map((rbName) => ({
			id: rbName,
			accessorFn: (row) => row.roadblocks[rbName],
			header: t(rbName.replace("_", " ")).capfirst(),
			mantineTableBodyCellProps: { align: "center" },
			size: 125,
			enableColumnFilter: false,
			sortingFn: (a, b) =>
				sortByCredits(
					a.original.roadblocks[rbName],
					b.original.roadblocks[rbName]
				),
			Cell: ({ row }) =>
				creditsOverviewSpan(row.original.roadblocks[rbName]),
		})),
		{
			id: "tepitech",
			accessorFn: (row) => row.roadblocks.tepitech,
			header: `${t("tepitech").capfirst()}`,
			mantineTableBodyCellProps: { align: "center" },
			Cell: ({ row }) =>
				creditsOverviewSpan(row.original.roadblocks.tepitech),
			size: 100,
			enableSorting: true,
			sortingFn: (a, b) =>
				sortByCredits(
					a.original.roadblocks.tepitech,
					b.original.roadblocks.tepitech
				),
			filterVariant: "range-slider",
			filterFn: (row, colId, filterValue) => {
				const min = filterValue[0];
				const max = filterValue[1];
				const { obtained } = row.original.roadblocks[colId];
				return obtained >= min && obtained <= max;
			},
			mantineFilterRangeSliderProps: {
				max: tepitech,
				min: 0,
				step: 10,
				minRange: 10,
			},
		},
	];
	const columnVisibility = {
		"student.gpa": false,
		"cursus": false,
	};
	if (city) columnVisibility["student.promo.city.name"] = false;
	if (promotion) columnVisibility["student.promo.promotion_year"] = false;
	return (
		<SauronMantineDataTable
			columns={columns}
			data={data}
			exportConfig={exportConfig}
			tableProps={{
				initialState: {
					columnVisibility,
				},
			}}
			title={t("roadblocks details").capfirst()}
			subtitle={t("per students")}
			isFetching={isFetching}
			isLoading={isLoading}
			isCard
		/>
	);
}
