import { t } from "../../Config/i18n";
import SauronMantineDataTable from "../Sauron/SauronMantineDataTable";
import { AlertBadge } from "../Utils/AlertBadge";
import { getAlertLabels } from "../Utils/Labels";
import LoginRow from "../Utils/LoginRow";
import { compare } from "../Utils/Utils";

export default function MasterDataTable({
	data,
	exportConfig,
	isLoading,
	isFetching,
}) {
	const alertLabels = getAlertLabels("master");
	const columns = [
		{
			accessorKey: "alerts",
			header: t("number of alerts").capfirst(),
			Cell: ({ row }) => (
				<AlertBadge
					max_alerts={Object.keys(alertLabels).length + 1}
					alerts={row.original.alerts.map(
						(alert) => alertLabels[alert]
					)}
				/>
			),
			sortingFn: (a, b) =>
				-compare(a.original.alerts.length, b.original.alerts.length),
			size: 100,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "login",
			header: t("login").capfirst(),
			size: 250,
			Cell: ({ row }) => (
				<LoginRow
					login={row.original.login}
					pageTooltip="master-student-tooltip"
				/>
			),
		},
		{
			accessorKey: "promotion",
			header: t("promotion").capfirst(),
			size: 100,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "semester",
			header: t("semester").capfirst(),
			size: 150,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "forecast",
			header: t("credits forecast").capfirst(),
			size: 125,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "available",
			header: t("available credits").capfirst(),
			size: 150,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "credits",
			header: t("credits obtained").capfirst(),
			size: 150,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "in_progress",
			header: t("credits in progress").capfirst(),
			size: 150,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "failed",
			header: t("credits failed").capfirst(),
			size: 150,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "referent",
			header: t("EIP referent").capfirst(),
			size: 200,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "eip4",
			header: t("EIP TECH4").capfirst(),
			size: 150,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "eip4_credits",
			header: `EIP TECH4 ${t("credits").capfirst()}`,
			size: 150,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "eip5",
			header: `EIP TECH5`,
			size: 150,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "eip5_credits",
			header: `EIP TECH5 ${t("credits").capfirst()}`,
			size: 150,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "part_time",
			header: t("part-time module").capfirst(),
			size: 150,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "part_time_credits",
			header: t("part-time credits").capfirst(),
			size: 150,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "free_project",
			header: t("free project module").capfirst(),
			size: 150,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "free_project_credits",
			header: t("free project credits").capfirst(),
			size: 100,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "modules_in_progress",
			header: t("modules in progress").capfirst(),
			size: 150,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "modules_failed",
			header: t("modules failed").capfirst(),
			size: 150,
			mantineTableBodyCellProps: { align: "center" },
			enableGlobalFilter: false,
		},
		{
			accessorKey: "tech4_destination",
			header: t("tech4 destination").capfirst(),
			size: 300,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "bachelor_city",
			header: t("bachelor city").capfirst(),
			size: 150,
			enableGlobalFilter: false,
		},
		{
			accessorKey: "nb_absences",
			header: t("nb_absences").capfirst(),
			size: 150,
			enableGlobalFilter: false,
		},
	];

	return (
		<SauronMantineDataTable
			title={t("roadblocks details").capfirst()}
			subtitle={t("per students")}
			columns={columns}
			data={data}
			exportConfig={exportConfig}
			isLoading={isLoading}
			isFetching={isFetching}
			isCard
		/>
	);
}
