import { useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { t } from "../../Config/i18n";
import { ScraperApiHelperContext } from "../../contexts";
import LoadingComponent from "./Loading";

function useEpitechAvatarQuery(login) {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["avatar", login],
		queryFn: () => scraper.unlabeled.getAvatar(login),
	});
}

export default function EpitechAvatar({ login, onErrorRender, ...restProps }) {
	const { data, error, isLoading } = useEpitechAvatarQuery(login);
	const avatar = data ? URL.createObjectURL(data) : null;

	if (isLoading) {
		return <LoadingComponent />;
	}
	if (error) {
		return (
			onErrorRender || (
				<FontAwesomeIcon
					data-place="bottom"
					data-tooltip-content={t(
						"no profile picture found"
					).capfirst()}
					data-tooltip-id="epitech-avatar-tooltip"
					icon={faUserTie}
					color="white"
					size="2x"
				/>
			)
		);
	}
	return <img {...restProps} src={avatar} alt={login} />;
}
