import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte";

import { t } from "../../Config/i18n";
import { HalloweenFooter } from "../Celebration/Halloween";

export default function Footer() {
	const footerStyle = {
		position: "relative",
	};
	return (
		<footer className="main-footer" style={footerStyle}>
			<strong>
				Copyright &copy; 2024
				<a target="_blank" href="https://epitech.eu" rel="noreferrer">
					{` Epitech`}
				</a>
				.
			</strong>
			{` ${t("all rights reserved").capfirst()}`}
			<HalloweenFooter />
		</footer>
	);
}
