import { Card } from "react-bootstrap";
import { Box, BoxTitle } from "../AdminLTE3/Box";
import LoadingOverlay from "../AdminLTE3/LoadingOverlay";
import FindOutMoreFooter from "../Dashboard/FindOutMoreFooter";

export function SauronChartsContainer({
	title,
	subtitle,
	topRightButton,
	isLoading,
	children,
	detailPath,
}) {
	return (
		<Box style={{ height: "100%" }}>
			<LoadingOverlay loading={isLoading} />
			<Card.Header>
				<BoxTitle title={title} subtitle={subtitle} />
				{topRightButton}
			</Card.Header>
			<Card.Body>
				<div style={{ height: 350 }}>{children}</div>
			</Card.Body>
			<FindOutMoreFooter detailPath={detailPath} />
		</Box>
	);
}
