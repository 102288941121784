import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { ScraperApiHelperContext } from "../../contexts";

export const useGroupStatsStudentQuery = (login) => {
	const scraper = useContext(ScraperApiHelperContext);
	return useQuery({
		queryKey: ["group", "stats", "student", login],
		queryFn: () => scraper.student.getGroupStats(login),
		select: (response) => response.data?.results,
		enabled: !!login,
	});
};
