import { DateTime } from "luxon";
import PapaParse from "papaparse";
import QS from "qs";
import { Tooltip } from "react-tooltip";
import Sauron from "../../Assets/sauron.svg";
import SauronChristmas from "../../Assets/sauron_christmas.png";
import SauronHalloween from "../../Assets/sauron_halloween.png";
import { t } from "../../Config/i18n";

export function round2Decimal(a) {
	return Math.round(a * 100) / 100;
}

/* remove keys from an object */
export function removeKeys(object, arrayKeys) {
	const dup = {};
	Object.keys(object).forEach((key) => {
		if (arrayKeys.includes(key) === false) {
			dup[key] = object[key];
		}
	});
	return dup;
}

/* create an object {Authorization: token} */
export function deployToken(jwtToken) {
	return { Authorization: jwtToken };
}

export function cacheDisabler() {
	return { cache_disabler: new Date().getMilliseconds() };
}

/* get month difference between two dates */
export function monthsDiff(begin, end) {
	const diff = end - begin;
	const days = diff / (1000 * 3600 * 24);
	return (days / 30.5).toFixed(1);
}

/* get current academical year */
export function getCurrentAcademicYear() {
	const now = new Date();
	const year = now.getFullYear();
	const month = now.getMonth();
	return month < 8 ? year - 1 : year;
}

/* it's like a ternary */
export function componentOrElse(statement, defaultComponent, elseComponent) {
	if (statement) {
		return defaultComponent;
	}
	return elseComponent;
}

export function getTekYear(
	promotion_year,
	year = getCurrentAcademicYear(),
	nb_years_in_curriculum = 5
) {
	return nb_years_in_curriculum + 1 - (promotion_year - year);
}

export function getTekPromotionYear(year, nb_years_in_curriculum, tek_year) {
	return year + nb_years_in_curriculum - tek_year + 1;
}

/* create an array of values */
export function nValues(start, length, step = 1) {
	return Array.from(Array(length), (_, i) => i * step + start);
}

/* get a text-color class from credit state */
export function colorFromCreditState(obtained, inprogress, min_required) {
	if (obtained >= min_required) {
		return "text-green";
	}
	if (inprogress + obtained >= min_required) {
		return "text-blue";
	}
	return "text-danger";
}

/* credit state span, XX (+ YY) / ZZ with colors and tooltip */
export function creditsOverviewSpan(roadblock, validated = null) {
	if (!roadblock) return <div className="text-muted">N/A</div>;
	const { obtained = 0, inprogress = 0, min_required = 0 } = roadblock;
	let className;
	if (validated === true) className = "text-green";
	else if (validated === false) className = "text-danger";
	else className = colorFromCreditState(obtained, inprogress, min_required);
	return (
		<div className={`${className}`}>
			{`${obtained} ${inprogress > 0 ? `(+${inprogress})` : ""} ${
				min_required > 0 ? ` / ${min_required}` : ""
			}`}
		</div>
	);
}

export function creditsOverviewSpanTepitech(tepitech, englishskills) {
	if (englishskills.min_required === -1) {
		if (tepitech.obtained >= tepitech.min_required)
			return creditsOverviewSpan(tepitech, true);
		return creditsOverviewSpan(tepitech, false);
	}
	if (
		tepitech.obtained >= tepitech.min_required ||
		englishskills.obtained >= englishskills.min_required
	)
		return creditsOverviewSpan(tepitech, true);
	return creditsOverviewSpan(tepitech, false);
}

export function creditsOverviewSpanEnglishSkills(tepitech, englishskills) {
	if (englishskills.min_required === -1) {
		if (tepitech.obtained >= tepitech.min_required)
			return creditsOverviewSpan(englishskills, true);
		return creditsOverviewSpan(englishskills, false);
	}
	if (
		tepitech.obtained >= tepitech.min_required ||
		englishskills.obtained >= englishskills.min_required
	)
		return creditsOverviewSpan(englishskills, true);
	return creditsOverviewSpan(englishskills, false);
}

export function creditsOverviewSpanEnglish(roadblocks) {
	if (!Object.prototype.hasOwnProperty.call(roadblocks, "englishskills"))
		return creditsOverviewSpan(roadblocks.tepitech);
	const english_propor =
		roadblocks.englishskills.obtained /
		roadblocks.englishskills.min_required;
	const tepitech_propor =
		roadblocks.tepitech.obtained / roadblocks.tepitech.min_required;
	if (tepitech_propor >= english_propor)
		return creditsOverviewSpan(roadblocks.tepitech);
	return creditsOverviewSpan(roadblocks.englishskills);
}

/* credit state span, XX (+ YY) / ZZ with colors and tooltip */
export function creditsWithMaximumOverviewSpan({ obtained = 0, maximum = 0 }) {
	const tooltip = `${t("acquired").capfirst()}: ${round2Decimal(
		obtained
	)}<br />${
		maximum > 0
			? `${"maximum".capfirst()}: ${round2Decimal(maximum)}<br />`
			: ""
	}`;
	return (
		<div
			data-tooltip-html={tooltip}
			data-tooltip-id="credits-with-maximum-tooltip"
			data-place="top"
			data-multiline="true"
			data-tooltip-float
		>
			{`${round2Decimal(obtained)} ${
				maximum > 0 ? `/ ${round2Decimal(maximum)}` : ""
			}`}
			<Tooltip id="credits-with-maximum-tooltip" />
		</div>
	);
}

/* basic comparison function for Array.sort() */
export function compare(a, b) {
	return a < b ? -1 : 1;
}

export function sortByCredits(a, b) {
	if (!a) return -1;
	if (!b) return 1;
	return compare(a.obtained + a.inprogress, b.obtained + b.inprogress);
}

/* change a decimal value to it's hex representation */
const decimal_to_hexa = (DecNb) => {
	const hexa = "0123465789ABCDEF";
	let x = Math.floor(DecNb / 16);
	let h = hexa.charAt(x);
	x = DecNb % 16;
	h += hexa.charAt(x);
	return h;
};

/* degrade a color from green to red */
export function degrade(max, value) {
	if (value === max) return "rgb(231, 76, 60)";
	if (value === 0) return "#00ba64";
	const fr = 255;
	const fg = 0;
	const fb = 0;
	const dr = 255;
	const dg = 255;
	const db = 0;
	let cr = dr;
	let cg = dg;
	let cb = db;
	const sr = (fr - dr) / max;
	const sg = (fg - dg) / max;
	const sb = (fb - db) / max;
	for (let x = 0; x < value; x++) {
		cr += sr;
		cg += sg;
		cb += sb;
	}
	return `#${decimal_to_hexa(cr)}${decimal_to_hexa(cg)}${decimal_to_hexa(cb)}`;
}

/* create a pre-allocated array with $value in each cell */
export function arrayMemset(size, value = 0) {
	const array = new Array(size);
	for (let i = 0; i < size; ++i) {
		array[i] = value;
	}
	return array;
}

/* get a percentage */
export function percentage(value, max = 100, min = 0) {
	return ((value - min) * 100) / (max - min);
}

/* just vomit a warning in the console */
export function defaultError(error) {
	console.log(error);
}

/* change params in the current URL */
export function changeParams(navigate, params) {
	navigate({
		pathname: window.location.pathname,
		search: QS.stringify(params),
	});
}

/* get the parsed URL params */
export function getURLparams(location) {
	return QS.parse(location.search, { ignoreQueryPrefix: true });
}

/* set the URL params from filters object */
export function setURLQueryFromFilters(navigate, filters) {
	const params = {};

	Object.entries(filters).forEach(([label, filter]) => {
		if (filter.selected) {
			switch (filter.type) {
				case null:
				case undefined:
				case "select":
					if (Array.isArray(filter.selected)) {
						params[label] = filter.selected.map((f) =>
							f.value === f.label ? f.value : f
						);
					} else {
						params[label] =
							filter.selected.value === filter.selected.label
								? filter.selected.value
								: filter.selected;
					}
					break;
				case "date":
					params[label] = filter.selected.toISODate();
					break;
				default:
					params[label] = filter.selected;
			}
		}
	});
	changeParams(navigate, params);
	return params;
}

/* create an option object {label, value} with label === value */
export function monoOption(label_value) {
	return { label: label_value, value: label_value };
}

/* set filters object from URL params */
export function setFiltersFromURLQuery(query, filters, setter = null) {
	Object.entries(query).forEach(([label, filter]) => {
		if (!filters[label]) return;
		switch (filters[label].type) {
			case "text":
				filters[label].selected = filter;
				break;
			case "bool":
				filters[label].selected = filter === "true";
				break;
			case "date":
				filters[label].selected = DateTime.fromISO(filter);
				break;
			case "select":
				if (filters[label].multiple) {
					filters[label].selected = filter.map((f) =>
						typeof f === "object" ? f : monoOption(f)
					);
				} else {
					filters[label].selected =
						typeof filter === "object"
							? filter
							: monoOption(filter);
				}
				break;
			default:
				console.log(
					`unhandled filter type: ${filters[label].type}. If it's a non-exising filter, fix it, otherwise, you can add your custom filter handler here`
				);
				filters[label].selected = filter;
				break;
		}
	});
	if (setter) setter(filters);
}

/* set options of a filter */
export function addOptionsToFilter(
	class_instance,
	filters,
	filter_name,
	options
) {
	filters[filter_name].options = options;
	if (class_instance.mounted) {
		class_instance.setState({
			filters,
		});
	}
}

/* Takes an array of CSV data and a title and download it as a document */
export function downloadCSV(dataTable, title) {
	const BOM_UTF8 = "\uFEFF";
	let csvContent = "";
	csvContent = PapaParse.unparse(dataTable, { delimiter: ";" });
	const blob = new Blob([`${BOM_UTF8}${csvContent}`], {
		type: "text/csv; charset=utf-8",
	});
	const element = document.createElement("a");
	element.href = URL.createObjectURL(blob);
	element.setAttribute(
		"download",
		`${title}_${new Date().toLocaleDateString()}.csv`.split(" ").join("_")
	);
	element.click();
	element.remove();
}

export function addOptionToFilterFunctional(
	stateSetter,
	filters,
	filter_name,
	options
) {
	filters[filter_name].options = options;
	stateSetter(filters);
}

/* Fill a select input if its options is only 1 length */

export function autofillInputsFilters(
	class_instance,
	options,
	key,
	multiple = false,
	reset = true
) {
	if (options.length === 1) {
		const { filters } = class_instance.state;
		let keyFilters = filters[key];
		if (multiple) {
			keyFilters = {
				...keyFilters,
				selected: [options[0]],
			};
		} else {
			keyFilters = {
				...keyFilters,
				selected: options[0],
			};
		}
		filters[key] = keyFilters;
		class_instance.setState({
			filters,
		});
	} else if (reset) {
		class_instance.setState({
			filters: {
				...class_instance.state.filters,
				[key]: {
					...class_instance.state.filters[key],
					selected: null,
				},
			},
		});
	}
	setURLQueryFromFilters(
		class_instance.props.navigate,
		class_instance.state.filters
	);
}

/* apply filters filter callbacks to rows */
export function applyFilters(filters, rows) {
	return rows.filter((row) => {
		const array = Object.entries(filters);
		for (let i = 0; i < array.length; i++) {
			const [, filter] = array[i];
			if (
				typeof filter.onFilter === "function" &&
				((filter.multiple === true &&
					filter.selected &&
					filter.selected.length > 0) ||
					(!filter.multiple && filter.selected) ||
					filter.selected === true) &&
				!filter.onFilter(row, filter.selected)
			) {
				return false;
			}
		}
		return true;
	});
}

/* apply a filter based on pre-filled fields and a search value to match */
export function filterFromSearch(fields, search) {
	if (fields.length === 0 || search.length === 0) {
		return true;
	}
	search = search.toLowerCase();
	if (fields.some((field) => field.toString().toLowerCase().includes(search)))
		return true;
	return false;
}

/* get a filter selected value, you can specify a field if you only want a certain field of the selected value */
export function getFilterSelection(filter, field = null) {
	switch (filter.type) {
		case "text":
			return filter.selected || "";
		case "bool":
			return filter.selected || false;
		case "date":
			return filter.selected || null;
		case "select":
			if (filter.multiple) {
				if (filter.selected && field)
					return filter.selected.extract(field);
				if (filter.selected && !field) return filter.selected;
				return [];
			}
			if (filter.selected && field) return filter.selected[field];
			if (filter.selected && !field) return filter.selected;
			return null;
		default:
			console.log(
				`unhandled filter type: ${filter.type}. If it's a non-exising filter, fix it, otherwise, you can add your custom filter handler here`
			);
			return null;
	}
}

/* Array overload to return an option Array made of {value, label} where value === value */
Object.defineProperty(Array.prototype, "toFilterOptions", {
	value() {
		return this.map(monoOption);
	},
});

/* Array overload to return each $key from each entry of the array */
Object.defineProperty(Array.prototype, "extract", {
	value(key) {
		return this.map((i) => i[key]);
	},
});

/* Return the correct sauron logo depending of the environment settings */
export function getSauronLogo() {
	if (import.meta.env.VITE_CELEBRATION === "halloween")
		return SauronHalloween;
	if (import.meta.env.VITE_CELEBRATION === "christmas")
		return SauronChristmas;
	return Sauron;
}

export function displayRangeElement(elementType, value, padding = false) {
	if (elementType === "min")
		return (
			<span
				className={padding === false ? "" : "tab1"}
				key={`${elementType}_${value}_min`}
			>
				Min: {value}
			</span>
		);
	if (elementType === "max")
		return (
			<span
				className={padding === false ? "" : "tab1"}
				key={`${elementType}_${value}_max`}
			>
				Max: {value}
			</span>
		);
	if (elementType === "less_than")
		return (
			<span
				className={padding === false ? "" : "tab1"}
				key={`${elementType}_${value}_less_than`}
			>
				{t("less than").capfirst()}: {value}
			</span>
		);
	if (elementType === "more_than")
		return (
			<span
				className={padding === false ? "" : "tab1"}
				key={`${elementType}_${value}_more_than`}
			>
				{t("more than").capfirst()}: {value}
			</span>
		);
	if (elementType === "exactly")
		return (
			<span
				className={padding === false ? "" : "tab1"}
				key={`${elementType}_${value}_exactly`}
			>
				{t("exactly").capfirst()}: {value}
			</span>
		);
	return null;
}

export function displayRange(range, padding = false) {
	let rangeDefined = false;
	const rangeElements = Object.entries(range).map(([key, data]) => {
		const rangeElement = displayRangeElement(key, data, padding);
		if (rangeElement) {
			rangeDefined = true;
		}
		return rangeElement;
	});
	if (!rangeDefined) {
		return (
			<span
				key="no_range"
				className="font-weight-bold font-italic text-red"
			>
				{t(
					"no range defined, the condition will always be true"
				).capfirst()}
			</span>
		);
	}
	return rangeElements;
}

/* Extract distinct possible grades for module_stats data */

export function getModuleStatsGrades(data) {
	const grades = [];

	Object.keys(data).forEach((key) => {
		Object.keys(data[key]).forEach((label) => {
			Object.keys(data[key][label]).forEach((grade) => {
				if (!grades.includes(grade)) grades.push(grade);
			});
		});
	});
	return grades;
}

/* Format data for get_module_stat route when retrieving only 1 year */

export function filterModuleStatsData(data) {
	const formatedData = {
		labels: [],
	};
	const grades = getModuleStatsGrades(data);
	Object.keys(data).forEach((key) => {
		Object.keys(data[key]).forEach((label) => {
			formatedData.labels.push(label);
			grades.forEach((grade) => {
				if (!(grade in formatedData)) formatedData[grade] = [];
				if (grade in data[key][label])
					formatedData[grade].push(data[key][label][grade]);
				else formatedData[grade].push(0);
			});
		});
	});
	return formatedData;
}

/* Format data for get_module_stat route when retrieving all years data */

export function filterModuleStatsDataByYear(data) {
	const formatedData = {
		labels: [],
	};
	const grades = getModuleStatsGrades(data);
	Object.keys(data).forEach((key) => {
		formatedData.labels.push(key);
		grades.forEach((grade) => {
			if (!(grade in formatedData)) formatedData[grade] = [];
			if (grade in data[key].Global)
				formatedData[grade].push(data[key].Global[grade]);
			else formatedData[grade].push(0);
		});
	});
	return formatedData;
}

export function compareArrays(first, second) {
	return JSON.stringify(first) === JSON.stringify(second);
}

export function putIndexAtEnd(data, index) {
	const i = data.labels.findIndex((e) => e === index);
	data.labels.splice(i, 1);
	data.labels.push(index);
	data.datasets.forEach((dataset) => {
		const tmp = dataset.data[i];
		dataset.data.splice(i, 1);
		dataset.data.push(tmp);
	});
}

/* Reformat data for graph in order to put national to the right */
export function setFranceToRight(data) {
	if (data.labels.length < 2 || !data.labels.includes("France")) return data;
	const lastLabel = data.labels[data.labels.length - 1];
	if (lastLabel === "France") return data;
	putIndexAtEnd(data, "France");
	return data;
}

/* Reformat data for graph in order to put national to the right */
export function setNationalToRight(data) {
	if (data.labels.length < 2 || !data.labels.includes("National"))
		return data;
	const lastLabel = data.labels[data.labels.length - 1];
	if (lastLabel === "National") return data;
	putIndexAtEnd(data, "National");
	return data;
}

/* Reformat data for graph in order to put France -> Europe -> Global to the right */

export function orderGraphByCity(data) {
	if (data.labels.length < 2 || !data.labels.includes("France")) return data;
	putIndexAtEnd(data, "France");
	return data;
}

/* Return an array of years from the current academic year to the year given as parameters
	ex: getYearsArray(2019) => [2021, 2020, 2019]
*/
export function getYearsArray(lastYear) {
	const years = [];
	for (
		let beginYear = getCurrentAcademicYear();
		beginYear >= lastYear;
		beginYear--
	)
		years.push({ value: beginYear, label: beginYear });
	return years;
}

export const forceSelectedCity = (city, setSelectedOption) => {
	setSelectedOption("city", city);
};

/* Return the corresponding administrative comment from the percent integer. (Specific to Intuitu Personae)
	ex: getAdmComment(0) => "Démission de fait (0%)"
*/
export const adm_status_comments = {
	null: "N/A",
	0: "Resignation done (0%)",
	20: "Gone to leave (20%)",
	50: "Undecided (50%)",
	80: "Will probably continue (80%)",
	100: "Will continue Epitech (100%)",
};

export const adm_status_values = [0, 20, 50, 80, 100];

export const getTranslatedAdmStatusComments = () => ({
	null: t("N/A"),
	0: t("Resignation done (0%)"),
	20: t("Gone to leave (20%)"),
	50: t("Undecided (50%)"),
	80: t("Will probably continue (80%)"),
	100: t("Will continue Epitech (100%)"),
});

/* Return the corresponding pedagogical status from the integer. (Specific to Intuitu Personae)
	ex: getPedagoStatus(0) => "A renoncé pour cette année"
*/
export const pedago_status_comments = {
	null: "N/A",
	0: "Has given up this year (0%)",
	20: "In high difficulty (20%)",
	40: "Struggling but could manage (40%)",
	60: "Gone for the synthesis (60%)",
	80: "Will acquire the skills (80%)",
	100: "No major difficulty (100%)",
};

export const pedago_status_values = [0, 20, 40, 60, 80, 100];

export const getTranslatedPedagoStatusComments = () => ({
	null: t("N/A"),
	0: t("Has given up this year (0%)"),
	20: t("In high difficulty (20%)"),
	40: t("Struggling but could manage (40%)"),
	60: t("Gone for the synthesis (60%)"),
	80: t("Will acquire the skills (80%)"),
	100: t("No major difficulty (100%)"),
});

export const sortObjectByKeys = (obj) =>
	Object.keys(obj)
		.sort()
		.reduce((acc, key) => {
			acc[key] = obj[key];
			return acc;
		}, {});

export function sortObjectByKeysWithFranceAtEnd(obj) {
	const keys = Object.keys(obj).sort();
	const franceIndex = keys.findIndex(
		(key) => key === "National" || key === "France"
	);
	if (franceIndex === -1) return sortObjectByKeys(obj);
	keys.push(keys.splice(franceIndex, 1)[0]);
	return keys.reduce((acc, key) => {
		acc[key] = obj[key];
		return acc;
	}, {});
}

export function sortObjectByKeysWithGlobalAtEnd(obj) {
	const keys = Object.keys(obj).sort();
	const globalIndex = keys.findIndex(
		(key) => key === "National" || key === "Global"
	);
	if (globalIndex === -1) return sortObjectByKeys(obj);
	keys.push(keys.splice(globalIndex, 1)[0]);
	return keys.reduce((acc, key) => {
		acc[key] = obj[key];
		return acc;
	}, {});
}

export function getNextPageParam(lastPage) {
	const next = lastPage?.data?.next || lastPage?.next;
	if (next) {
		const urlSearchParams = new URLSearchParams(next);
		const params = Object.fromEntries(urlSearchParams.entries());
		return params.offset;
	}
	return null;
}

export const promotionYearToBachelorPromotion = (
	promotionYear,
	scholarYear
) => {
	const yearDiff = promotionYear - scholarYear;
	const diffToCurriculum = {
		5: "PGE1",
		4: "PGE2",
		3: "PGE3",
		2: "PGE4",
		1: "PGE5",
	};
	return diffToCurriculum[yearDiff];
};

const calculateContractPercentage = ({ total, contracts }) => {
	if (total === 0) return 0;
	return (contracts / total) * 100;
};

export const selectInternshipProgression = (data, scholarYear) => {
	const response = data.data;
	return Object.keys(response).reduce((acc, isoWeek) => {
		Object.keys(response[isoWeek]).forEach((promotionYear) => {
			const promotion = promotionYearToBachelorPromotion(
				promotionYear,
				scholarYear
			);
			Object.keys(response[isoWeek][promotionYear]).forEach((city) => {
				const key = `${
					city === "National" ? "Global" : city
				} - ${promotion} - ${scholarYear}`;
				if (!(key in acc)) acc[key] = [];
				const contractPercentage = calculateContractPercentage(
					response[isoWeek][promotionYear][city]
				);
				acc[key].push(contractPercentage.toFixed(2));
			});
		});
		return acc;
	}, {});
};

export function exportProgression(progressionStats, labels, title) {
	if (!progressionStats || Object.keys(progressionStats).length === 0) return;
	if (!labels || labels.length === 0) return;
	const table = [
		[
			"city",
			"promotion",
			"scholar year",
			...labels.map((label) => `${label}`),
		],
	];
	Object.entries(progressionStats).forEach(([information, stats]) => {
		const splitInformation = information.split(" - ");
		const city = splitInformation[0];
		const promotion = splitInformation[1];
		const promotionYear = splitInformation[2];
		const copy = [...stats];
		copy.splice(labels.length, copy.length - labels.length);
		table.push([
			`${city}`,
			`${promotion}`,
			`${promotionYear}`,
			...copy.map((stat) => `${stat}%`),
		]);
	});
	downloadCSV(table, title);
}

export const getLabelWeeks = (maxLength, iteration = 1) => {
	const currentYear = getCurrentAcademicYear();
	const labels = [];
	let week = DateTime.fromISO(`${currentYear}-W36`);
	while (
		labels.length < maxLength &&
		(week.weekNumber !== 36 || week.year < currentYear + 1)
	) {
		labels.push(week);
		week = week.plus({ week: iteration });
	}
	return labels;
};

export const separateFiltersOptions = (options) =>
	options.reduce(
		(acc, { label, value }) => {
			acc.labels.push(label);
			acc.values.push(value);
			return acc;
		},
		{
			labels: [],
			values: [],
		}
	);

export function getPGEPromotionOptions(
	includeMaster = false,
	includeBachelor = true
) {
	const currentYear = getCurrentAcademicYear();
	const numberList = [
		...(includeMaster ? [5, 4] : []),
		...(includeBachelor ? [3, 2, 1] : []),
	];

	return numberList
		.map((num, idx) => ({
			label: `PGE${num}`,
			value: currentYear + idx + (includeMaster ? 1 : 3),
		}))
		.reverse();
}

export function createIntuituADMOption(admStatus) {
	const admComments = getTranslatedAdmStatusComments();
	return {
		label: admComments[admStatus],
		value: parseInt(admStatus, 10),
	};
}

export function createIntuituPedagoOption(pedagoStatus) {
	const pedagoComments = getTranslatedPedagoStatusComments();
	return {
		label: pedagoComments[pedagoStatus],
		value: parseInt(pedagoStatus, 10),
	};
}

export function isInGroup(account, group) {
	return account.roles.includes("admin") || account.roles.includes(group);
}

export const UPLOAD_STATUS = {
	pending: {
		label: "waiting for upload",
		colorVariant: "primary",
	},
	success: {
		label: "successfully imported",
		colorVariant: "success",
	},
	error: {
		label: "import error",
		colorVariant: "danger",
	},
};

export const promotionYearToCurriculum = (promotionYear, scholarYear) => {
	const yearDiff = promotionYear - scholarYear;
	const diffToCurriculum = {
		5: "PGE1",
		4: "PGE2",
		3: "PGE3",
		1: "PGE5",
	};
	return diffToCurriculum[yearDiff];
};

export function hasPermissions(page, account) {
	if (!page.authorized_groups || page.authorized_groups.includes("*"))
		return true;
	if (!account || !account.roles) return false;
	if (account.roles.includes("admin")) return true;
	if (account.roles.some((group) => page.authorized_groups.includes(group)))
		return true;
	return false;
}
